<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4">
        <template #start>
          <Button label="Seite hinzufügen" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew(null)" />
        </template>

        <template #end>
          <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import"
            class="mr-2 d-inline-block" />
          <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
        </template>
      </Toolbar>

      <DataTable ref="dt" :value="view_page" dataKey="_id" :loading="loading" :paginator="true" :rows="50"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen" responsiveLayout="scroll"
        sortField="index" :sortOrder="1" :reorderableColumns="false">
        <template #header>
          <div class="table-header-container">
            <h4>Seiten-Manager</h4>
          </div>
        </template>
        <Column field="icon" header="Icon" :sortable="false">
          <template #body="slotProps">
            <Button v-if="slotProps.data.icon" :icon="slotProps.data.icon"
              class="p-button-rounded p-button-primary mr-2" />
          </template>
        </Column>
        <Column field="label" header="Name" :sortable="true"></Column>
        <Column field="name" header="Pfad" :sortable="true">
          <template #body="slotProps">
            <span>/view/{{ slotProps.data.name }}</span>
          </template>
        </Column>
        <Column field="content" header="Beschreibung" :sortable="true"> </Column>
        <Column field="type" header="Typ" :sortable="true"></Column>
        <Column :exportable="false" style="min-width: 8rem">
          <template #body="slotProps">
            <router-link :to="'/view/' + slotProps.data.name">
              <a><Button icon="pi pi-link" class="p-button-rounded p-button-info mr-2" /></a>
            </router-link>

            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editEntry(slotProps.data)" />
            <Button icon="pi pi-copy" class="p-button-rounded p-button-secondary mr-2" @click="openNew(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteEntry(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog v-model:visible="configDialog" :style="{ width: '450px' }" header="Seite bearbeiten" :modal="true"
      class="p-fluid" @hide="hideDialog">
      <div class="field">
        <label for="label">Name</label>
        <InputText id="label" v-model.trim="ioDialogData.label" required="true" autofocus
          :class="{ 'p-invalid': submitted && !ioDialogData.label }" />
        <small class="p-error" v-if="submitted && !ioDialogData.label">Name <b>muss</b> ausgefüllt werden!</small>
      </div>
      <div class="field">
        <label for="name">Pfad</label>
        <InputText id="name" v-model.trim="ioDialogData.name" required="false" autofocus />
      </div>
      <div class="field">
        <label for="typ" class="mb-3">Typ</label>
        <Dropdown id="typ" v-model="ioDialogData.type" :options="types" optionLabel="label" optionValue="value">
        </Dropdown>
      </div>
      <div class="field">
        <label for="content">Beschreibung</label>
        <Textarea id="content" v-model="ioDialogData.content" required="true" rows="3" cols="20" />
      </div>
      <div class="field">
        <label for="icon">Icon</label>
        <Dropdown id="icon" v-model="ioDialogData.icon" :filter="true" placeholder="Icon wählen"
          :options="getIcons" optionLabel="key" optionValue="icon">
          <template #value="slotProps">
            <div>
              <span :class="slotProps.value"></span>
              <span class="text-small"> [{{ slotProps.value }}]</span>
            </div>
          </template>

          <template #option="slotProps">
            <div>
              <span class="text-5xl" :class="slotProps.option.icon"></span>
              <span class="text-small"> [{{ slotProps.option.key }}]</span>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="formgrid grid">
        <div class="field col">
          <label for="background">Hintergrund</label>
          <Dropdown id="style.background" v-model="ioDialogData.style.background" :options="colors" optionLabel="label"
            optionValue="value">
            <template #value="slotProps">
              <div :class="'bg-' + slotProps.value">
                <span>Hintergrund</span>
              </div>
            </template>
            <template #option="slotProps">
              <div :class="'bg-' + slotProps.option.value">
                <span>{{ slotProps.option.label }}</span>
              </div>
            </template>
          </Dropdown>
        </div>

        <div class="field col">
          <label for="foreground">Vordergrund</label>
          <Dropdown id="style.foreground" v-model="ioDialogData.style.foreground" :options="colors" optionLabel="label"
            optionValue="value">
            <template #value="slotProps">
              <div :class="'fg-' + slotProps.value">
                <span>Vordergrund</span>
              </div>
            </template>
            <template #option="slotProps">
              <div :class="'fg-' + slotProps.option.value">
                <span>{{ slotProps.option.label }}</span>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>

      <div v-if="ioDialogData.components.length > 0">
        <div class="formgrid grid">
          <div class="field col">
            <label for="componentsList">Widgets ({{ widgetCount }})</label>
            <Listbox id="componentsList" v-model="selectedComponent" :options="ioDialogData.components" optionLabel="component">
              <template #option="slotProps">
                <div>
                  <span>[{{ slotProps.option.i }}] {{ slotProps.option.component }}</span>
                </div>
              </template>
            </Listbox>
          </div>
        </div>
        <div class="formgrid grid">
          <div class="field col">
            <Button label="ausgewähltes Widget löschen" icon="pi pi-minus" class="p-button-warning mr-2" :disabled="!selectedComponent" @click="deleteWidget(selectedComponent)" />
          </div>
          <div class="field col">
            <Button label="alle Widget löschen" icon="pi pi-trash" class="p-button-danger mr-2" @click="deleteWidget(null)" />
          </div>
        </div>
      </div>

      <div v-if="ioDialogData._id" class="formgrid grid">
        <div class="field col">
          <label>ID</label>
          <InputText id="_id" v-model="ioDialogData._id" readonly />
        </div>
      </div>

      <template #footer>
        <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
        <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDialog" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteDialog" :style="{ width: '450px' }" header="Bestätigung" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="ioDialogData">Soll der Eintrag wirklich aus der Datenbank gelöscht werden: <br />
          <b>{{ ioDialogData.label }}</b> ?</span>
      </div>
      <template #footer>
        <Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false" />
        <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deletePage" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { findIndexByTag, mongoResponseToast } from '../helpers';
import SocketioService from "@/services/socketioService.js";
import { mapGetters } from "vuex";

export default {
  name: "PageTable",
  data() {
    return {
      loading: false,
      products: null,
      configDialog: false,
      subDialog: false,
      deleteDialog: false,
      deleteProductsDialog: false,
      deleteSubEntryDialog: false,
      product: {},
      config_io: null,
      ioDialogData: {},
      ioDialogID: null,
      view_page: [],
      view_page_selection: [],
      view_page_index: [],
      system_usergroup: null,
      selectedProducts: null,
      selectedIOs: null,
      selectedComponent: null,
      filters: {},
      submitted: false,
      intval_Value: null,
      columns: null,
      expandedRows: [],
      types: [
        { label: "Dashboard-Grid", value: "grid" },
        { label: "Statische Boxen", value: "boxes" },
        { label: "Canvas", value: "canvas" },
        { label: "Sonder", value: "special" },
      ],
    };
  },
  productService: null,
  created() {
    this.initFilters();
    this.$store.dispatch('types/loadCategoryTypes');
    this.$store.dispatch('types/loadIconTypes');
  },
  mounted() {
    this.loading = true;
    this.getPageData();
  },
  beforeUnmount() {
    this.loading = false;
    this.products = null;
    this.configDialog = false;
    this.subDialog = false;
    this.deleteDialog = false;
    this.deleteProductsDialog = false;
    this.deleteSubEntryDialog = false;
    this.product = {};
    this.config_io = null;
    this.ioDialogData = {};
    this.ioDialogID = null;
    this.view_page = [];
    this.view_page_selection = [];
    this.view_page_index = [];
    this.system_usergroup = null;
    this.selectedProducts = null;
    this.selectedIO = null;
    this.selectedComponent = null;
    this.filters = {};
    this.submitted = false;
    this.intval_Value = null;
    this.columns = null;
    this.expandedRows = [];
  },
  computed: {
    ...mapGetters('types', ['getCategory', 'getIcons']),
    colors: function () {
      return this.$store.getters.getStyleColors;
    },
    patterns: function () {
      return this.$store.getters.getStylePatterns;
    },
    widgetCount: function () {
      if (this.ioDialogData.components.length > 0) {
        return this.ioDialogData.components.length;
      } else {
        return 0;
      }
    },
  },
  methods: {
    resetDialogData() {
      this.ioDialogData = {
        name: null,
        label: null,
        content: null,
        type: null,
        icon: null,
        style: {
          background: null,
          foreground: null,
        },
        components: [],
      };
      this.view_page_index = [];
      this.ioDialogID = null;
      this.selectedComponent = null;
    },
    getPageData() {
      this.loading = true;
      SocketioService.getViewPage((err, response) => {
        if (!err && response !== null) {
          this.view_page = [ ...response ];
          this.loading = false;
        }
      });
    },
    openNew(ioData) {
      this.resetDialogData();
      if (ioData !== null) {
        const _obj = JSON.parse(JSON.stringify(ioData));
        this.ioDialogData = _obj;
        this.ioDialogData._id = null;
      } else {
        this.ioDialogData.icon = "mif-meter";
        this.ioDialogData.type = "grid"
      }
      this.submitted = false;
      this.getPageData();
      this.configDialog = true;
    },
    // openNew() {
    //   this.resetDialogData();
    //   this.submitted = false;
    //   this.configDialog = true;
    // },
    hideDialog() {
      this.configDialog = false;
      this.submitted = false;
      this.resetDialogData();
    },
    saveDialog() {
      this.submitted = true;
      this.loading = true;

      if (this.ioDialogData.label.trim()) {
        SocketioService.setViewPage(this.ioDialogData, (err, response) => {
          if (!err && response !== null) {
            this.loading = false;
            const result = mongoResponseToast(response, this.$root);
            if (result) this.getPageData();
          }
        });
        this.configDialog = false;
        this.resetDialogData();
      }
    },
    editEntry(ioData) {
      console.log(ioData);
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.configDialog = true;
    },
    confirmDeleteEntry(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteDialog = true;
    },
    deletePage() {
      this.loading = true;
      SocketioService.delViewPage(this.ioDialogData, (err, response) => {
        if (!err && response !== null) {
          this.loading = false;
          const result = mongoResponseToast(response, this.$root);
          if (result) this.getPageData();
        }
      });
      this.deleteDialog = false;
      this.resetDialogData();
    },
    deleteWidget(component) {
      if (component !== null) {
        let index;
        if (component.i !== undefined) {
          index = findIndexByTag(this.ioDialogData.components, 'i', this.selectedComponent.i);
        } else if (component.id !== undefined) {
          index = findIndexByTag(this.ioDialogData.components, 'id', this.selectedComponent.id);
        }
        console.log(index);
        console.log(this.ioDialogData.components[index]);
        this.ioDialogData.components.splice(index, 1);
      } else {
        this.ioDialogData.components = [];
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.view_page.length; i++) {
        if (this.view_page[i]._id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      SocketioService.removeValue(this.selectedIOs);
      this.deleteProductsDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>